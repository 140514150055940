<template>
  <t-split-screen centered>
    <div class="content--centered">
      <div v-if="status === Status.LOADING" style="display: flex">
        <span class="loading mt-1" style="flex-shrink: 0; margin-right: 20px" />
        <div>
          <h1>
            {{ $t('pages.terms_of_service.accept.heading') }}
          </h1>
        </div>
      </div>
      <div v-else-if="status === Status.ACCEPTED" style="display: flex">
        <t-icon icon="check" boxed type="success" style="flex-shrink: 0; margin-right: 20px" />
        <div>
          <h1>{{ $t('pages.terms_of_service.accepted.heading') }}</h1>
          <p>{{ $t('pages.terms_of_service.accepted.subheading') }}</p>
        </div>
      </div>
      <div v-else-if="status === Status.ALREADY_ACCEPTED" style="display: flex">
        <t-icon icon="check" boxed type="success" style="flex-shrink: 0; margin-right: 20px" />
        <div>
          <h1>{{ $t('pages.terms_of_service.already_accepted.heading') }}</h1>
          <p>{{ $t('pages.terms_of_service.already_accepted.subheading') }}</p>
        </div>
      </div>
      <div v-else-if="status === 'error'" style="display: flex">
        <t-icon icon="exclamation" boxed type="danger" style="flex-shrink: 0; margin-right: 20px" />
        <div>
          <h1>
            {{ $t('pages.terms_of_service.actions.verification_failed') }}
          </h1>
        </div>
      </div>
    </div>
    <dashboard-image slot="secondary" name="terms-of-service" />
  </t-split-screen>
</template>
<script>
import DashboardImage from '~/components/DashboardImage';
import LegalContact from '~/models/LegalContact';
import ApiCodeEnum from '~/enums/ApiCodeEnum';

class Status {
  static LOADING = 'loading';
  static ACCEPTED = 'accepted';
  static ALREADY_ACCEPTED = 'already_accepted';
  static ERROR = 'error';
}

export default {
  components: {
    DashboardImage,
  },

  layout: 'standalone',

  data: () => ({
    status: Status.LOADING,
    Status,
  }),

  computed: {
    id() {
      return this.$route.params.legalContactId;
    },

    token() {
      return this.$route.query.token;
    },
  },

  beforeMount() {
    this.verify();
  },

  methods: {
    async verify() {
      try {
        await new LegalContact({ id: this.id }).verify({
          token: this.token,
        });
        this.status = Status.ACCEPTED;
      } catch (e) {
        if (e?.response?.data?.key === ApiCodeEnum.APPLICANTS.TERMSOFSERVICE_ALREADY_ACCEPTED) {
          this.status = Status.ALREADY_ACCEPTED;
          return;
        }

        this.status = Status.ERROR;
        this.$axios.handleError(e);
      }
    },
  },
};
</script>
